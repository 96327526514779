import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c840aa7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-file" }
const _hoisted_2 = ["id", "disabled", "accept", "value"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      onChange: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)), ["prevent"])),
      id: _ctx.inputId,
      disabled: _ctx.disabled,
      accept: _ctx.accept,
      value: _ctx.value,
      type: "file"
    }, null, 40, _hoisted_2),
    _createVNode(_component_AppButton, {
      disabled: _ctx.disabled,
      onKeydown: _withKeys(_withModifiers(_ctx.click, ["prevent"]), ["space","enter"])
    }, {
      default: _withCtx(() => [
        _createElementVNode("label", { for: _ctx.inputId }, _toDisplayString(_ctx.label), 9, _hoisted_3)
      ]),
      _: 1
    }, 8, ["disabled", "onKeydown"])
  ]))
}