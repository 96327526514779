import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-84fb35a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheSpotlight = _resolveComponent("TheSpotlight")!
  const _component_TheSearchImageOnTopPage = _resolveComponent("TheSearchImageOnTopPage")!
  const _component_CollectionList = _resolveComponent("CollectionList")!
  const _component_TheSiteGuidance = _resolveComponent("TheSiteGuidance")!
  const _component_TheSiteDescription = _resolveComponent("TheSiteDescription")!
  const _component_NewsList = _resolveComponent("NewsList")!
  const _component_TheOnlineServices = _resolveComponent("TheOnlineServices")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TheSpotlight),
    _createVNode(_component_TheSearchImageOnTopPage, { imageQueries: _ctx.imageQueries }, null, 8, ["imageQueries"]),
    _createVNode(_component_CollectionList, {
      heading: _ctx.$t('form.collection'),
      collections: _ctx.collections
    }, null, 8, ["heading", "collections"]),
    _createVNode(_component_TheSiteGuidance, {
      id: "top-page-site-guidance",
      class: "top-page-site-guidance"
    }),
    _createVNode(_component_TheSiteDescription, { class: "top-page-site-description" }),
    _createVNode(_component_NewsList),
    _createVNode(_component_TheOnlineServices, { class: "top-page-online-services" })
  ]))
}