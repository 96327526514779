import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3356b3e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-site-guidance has-background-contents shadow" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "the-site-guidance-body" }
const _hoisted_4 = { class: "the-site-guidance-table" }
const _hoisted_5 = { class: "the-site-guidance-table-title" }
const _hoisted_6 = { class: "has-background-page" }
const _hoisted_7 = { class: "has-background-page" }
const _hoisted_8 = { class: "the-site-guidance-list" }
const _hoisted_9 = { class: "has-background-page" }
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "has-background-page" }
const _hoisted_13 = {
  key: 0,
  class: "the-site-guidance-list"
}
const _hoisted_14 = { class: "the-site-guidance-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIconPlaces = _resolveComponent("AppIconPlaces")!
  const _component_AppLabelPlaces = _resolveComponent("AppLabelPlaces")!
  const _component_GuidanceCell = _resolveComponent("GuidanceCell")!
  const _component_TextLinkWithArrow = _resolveComponent("TextLinkWithArrow")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('guidance.title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('guidance.description')), 1),
      _createElementVNode("table", _hoisted_4, [
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", _hoisted_5, [
            _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t('guidance.thType.head')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accessRestrictions, (accessRestriction, index) => {
              return (_openBlock(), _createElementBlock("td", { key: index }, [
                _createVNode(_component_AppLabelPlaces, {
                  type: accessRestriction.value,
                  tag: "div",
                  fill: true,
                  class: "the-site-guidance-place-label"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AppIconPlaces, {
                      type: accessRestriction.value
                    }, null, 8, ["type"]),
                    _createTextVNode(" " + _toDisplayString(accessRestriction.label), 1)
                  ]),
                  _: 2
                }, 1032, ["type"])
              ]))
            }), 128))
          ]),
          _createElementVNode("tr", null, [
            _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t('guidance.thResource.head', { newLine: '\r\n' })), 1),
            _createElementVNode("td", null, [
              _createVNode(_component_GuidanceCell, {
                "main-message": _ctx.$t('guidance.thResource.internet.text'),
                "place-icon-type": "internet"
              }, null, 8, ["main-message"])
            ]),
            _createElementVNode("td", null, [
              _createVNode(_component_GuidanceCell, {
                "main-message": _ctx.$t('guidance.thResource.ooc.text'),
                "place-icon-type": "ooc"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("ul", _hoisted_8, [
                    _createElementVNode("li", null, _toDisplayString(_ctx.$t('guidance.thResource.ooc.text1')), 1),
                    _createElementVNode("li", null, _toDisplayString(_ctx.$t('guidance.thResource.ooc.text2')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["main-message"])
            ]),
            _createElementVNode("td", null, [
              _createVNode(_component_GuidanceCell, {
                "main-message": _ctx.$t('guidance.thResource.inlibrary.text'),
                "place-icon-type": "inlibrary"
              }, null, 8, ["main-message"])
            ])
          ]),
          _createElementVNode("tr", null, [
            _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t('guidance.thMethod.head', { newLine: '\r\n' })), 1),
            _createElementVNode("td", null, [
              _createVNode(_component_GuidanceCell, {
                "main-message": _ctx.$t('guidance.thMethod.internet.text'),
                "place-icon-type": "internet"
              }, null, 8, ["main-message"])
            ]),
            _createElementVNode("td", null, [
              _createVNode(_component_AppLabelPlaces, {
                type: "ooc",
                tag: "span",
                class: "is-hidden-desktop"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_AppIconPlaces, { type: "ooc" })
                ]),
                _: 1
              }),
              _createElementVNode("div", null, [
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('guidance.thMethod.ooc.text1')) + " ", 1),
                  _createElementVNode("a", {
                    href: _ctx.$t('link.transmissionServiceForIndividuals'),
                    rel: "noopener",
                    class: "level-left is-mobile",
                    style: {"display":"inline"}
                  }, _toDisplayString(_ctx.$t('guidance.thMethod.ooc.text2')), 9, _hoisted_10),
                  _createTextVNode(_toDisplayString(_ctx.$t('guidance.thMethod.ooc.text3', { newLine: '\r\n' })) + " ", 1),
                  _createElementVNode("a", {
                    href: _ctx.$t('link.transmissionServiceForLibraries'),
                    rel: "noopener",
                    class: "level-left is-mobile",
                    style: {"display":"inline"}
                  }, _toDisplayString(_ctx.$t('guidance.thMethod.ooc.text4')), 9, _hoisted_11),
                  _createTextVNode(_toDisplayString(_ctx.$t('guidance.thMethod.ooc.text5')), 1)
                ]),
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ])
            ]),
            _createElementVNode("td", null, [
              _createVNode(_component_GuidanceCell, {
                "main-message": _ctx.$t('guidance.thMethod.inlibrary.text'),
                "place-icon-type": "inlibrary"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("ul", null, [
                    _createElementVNode("li", null, [
                      _createVNode(_component_TextLinkWithArrow, {
                        href: _ctx.$t('link.access.TokyoMainLibrary')
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('guidance.thMethod.inlibrary.building1')), 1)
                        ]),
                        _: 1
                      }, 8, ["href"])
                    ]),
                    _createElementVNode("li", null, [
                      _createVNode(_component_TextLinkWithArrow, {
                        href: _ctx.$t('link.access.KansaiKan')
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('guidance.thMethod.inlibrary.building2')), 1)
                        ]),
                        _: 1
                      }, 8, ["href"])
                    ]),
                    _createElementVNode("li", null, [
                      _createVNode(_component_TextLinkWithArrow, {
                        href: _ctx.$t('link.access.InternationalLibraryOfChildrensLiterature')
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('guidance.thMethod.inlibrary.building3')), 1)
                        ]),
                        _: 1
                      }, 8, ["href"])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["main-message"])
            ])
          ]),
          _createElementVNode("tr", null, [
            _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.$t('guidance.thTarget.head', { newLine: '\r\n' })), 1),
            _createElementVNode("td", null, [
              _createVNode(_component_GuidanceCell, {
                "main-message": _ctx.$t('guidance.thTarget.internet.text'),
                "place-icon-type": "internet"
              }, null, 8, ["main-message"])
            ]),
            _createElementVNode("td", null, [
              _createVNode(_component_GuidanceCell, {
                "main-message": _ctx.$t('guidance.thTarget.ooc.text'),
                "place-icon-type": "ooc"
              }, {
                default: _withCtx(() => [
                  (_ctx.lang == 'ja')
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                        _createElementVNode("li", null, _toDisplayString(_ctx.$t('guidance.thTarget.ooc.text1')), 1),
                        _createElementVNode("li", null, _toDisplayString(_ctx.$t('guidance.thTarget.ooc.text2')), 1),
                        _createElementVNode("li", null, _toDisplayString(_ctx.$t('guidance.thTarget.ooc.text3')), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["main-message"])
            ]),
            _createElementVNode("td", null, [
              _createVNode(_component_GuidanceCell, {
                "main-message": _ctx.$t('guidance.thTarget.inlibrary.text'),
                "place-icon-type": "inlibrary"
              }, null, 8, ["main-message"])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('guidance.signUpDescription')), 1),
        _createVNode(_component_ButtonIcon, {
          icon: "person-plus",
          styled: "is-accent",
          text: _ctx.$t('guidance.signUp'),
          class: "title",
          id: "user-registration-button",
          onclick: `location.href='` + _ctx.$t('link.signUp') + `'`
        }, null, 8, ["text", "onclick"])
      ])
    ])
  ]))
}