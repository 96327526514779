
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    file: {
      type: File,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    const src = ref()
    const fr = new FileReader()
    fr.readAsDataURL(props.file)
    fr.onload = () => {
      src.value = fr.result
    }

    return {
      src,
    }
  },
})
