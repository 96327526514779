
import { defineComponent, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import DssIcon from '../atoms/DssIcon.vue'
import AppImg from '../atoms/AppImg.vue'
import AppInput from '../atoms/AppInput.vue'
import AppButton from '../atoms/AppButton.vue'
import ButtonIcon from '../molecules/ButtonIcon.vue'
import InputImageFile from '@/components/molecules/InputImageFile.vue'
import { getBlob } from '@/helpers/util/webApiUtil'

const isServer = typeof window === 'undefined'

export default defineComponent({
  components: {
    DssIcon,
    AppImg,
    AppInput,
    AppButton,
    ButtonIcon,
    InputImageFile,
  },
  props: {
    imageQueries: {
      type: Array,
      default: () => [],
    },
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const state = reactive<{
      file: File | null
      imageUrl: string
      isErrorWebImageSearch: boolean
    }>({
      file: null,
      imageUrl: '',
      isErrorWebImageSearch: false,
    })

    const isVaildWebImageSearch = computed(() => state.imageUrl.length !== 0 && ['.jpg', '.png', '.bmp'].includes(state.imageUrl.substring(state.imageUrl.length - 4)))

    const searchImage = async (files: FileList | null) => {
      if (files) {
        state.file = files[0]
      } else {
        return
      }
      try {
        await store.dispatch('uploadFile', state.file)
        router.push({
          name: 'SearchImageResult',
          query: {
            image: store.getters?.PictureFile?.key || state.file?.name,
          },
        })
      } catch (error: any) {
        // TODO エラーハンドリング
        console.error(error.message)
      }
    }

    const searchSampleImage = (id: string) => {
      if (!isServer) {
        const img: any = document.querySelector(
          `.image-search-sample-${id} img`
        )
        const canvas: any = document.createElement('canvas')
        canvas.width = img.naturalWidth
        canvas.height = img.naturalHeight
        canvas.getContext('2d').drawImage(img, 0, 0)

        canvas.toBlob(
          async (blob: any) => {
            await store.dispatch('uploadFile', blob)
            router.push({
              name: 'SearchImageResult',
              query: {
                image: store.getters?.PictureFile?.key,
              },
            })
          },
          'image/jpeg',
          1.0
        )
      }
    }

    let isSearching = false

    const searchImageByUrl = async (url: string) => {
      if (isSearching) return
      isSearching = true
      if (isVaildWebImageSearch.value) {
        const blob = await getBlob(`${process.env.VUE_APP_PICTURE_API_URL}?url=${encodeURIComponent(url)}`)
        await store.dispatch('uploadFile', blob)
        router.push({
          name: 'SearchImageResult',
          query: {
            image: store.getters?.PictureFile?.key,
          },
        })
      }
      isSearching = false
    }

    return {
      state,
      isVaildWebImageSearch,
      searchImage,
      searchSampleImage,
      searchImageByUrl,
    }
  },
})
