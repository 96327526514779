
import { useI18n } from 'vue-i18n'
import { defineComponent } from '@vue/runtime-core'
import AppIconPlaces from '@/components/atoms/AppIconPlaces.vue'
import AppLabelPlaces from '@/components/atoms/AppLabelPlaces.vue'
import ButtonIcon from '@/components/molecules/ButtonIcon.vue'
import TextLinkWithArrow from '@/components/molecules/TextLinkWithArrow.vue'
import GuidanceCell from '@/components/molecules/GuidanceCell.vue'

export default defineComponent({
  name: 'TheSiteUsageGuidance',
  components: {
    ButtonIcon,
    AppIconPlaces,
    AppLabelPlaces,
    TextLinkWithArrow,
    GuidanceCell,
  },
  setup () {
    const i18n = useI18n()
    const lang = i18n.locale
    const accessRestrictions = [
      { value: 'internet', label: i18n.t('guidance.accessRestriction.internet') },
      { value: 'ooc', label: i18n.t('guidance.accessRestriction.ooc') },
      { value: 'inlibrary', label: i18n.t('guidance.accessRestriction.inlibrary') },
    ]
    return {
      lang,
      accessRestrictions,
    }
  },
})
