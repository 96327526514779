
import { defineComponent, ref } from 'vue'
import AppButton from '../atoms/AppButton.vue'

export default defineComponent({
  components: {
    AppButton,
  },
  props: {
    inputId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      required: false,
    },
  },
  setup (props, context) {
    const change = (e: Event) => {
      const files = (e.target as HTMLInputElement).files
      context.emit('inputFile', files)
    }

    const value = ref()
    const clear = () => {
      value.value = null
    }

    const click = () => {
      document.getElementById(props.inputId)!.click()
    }

    return {
      change,
      value,
      clear,
      click,
    }
  },
})
