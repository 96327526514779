
import { defineComponent } from 'vue'
import TheSpotlight from '@/components/organisms/TheSpotlight.vue'
import CollectionList from '@/components/organisms/CollectionList.vue'
import NewsList from '@/components/organisms/NewsList.vue'
import TheSiteDescription from '@/components/organisms/TheSiteDescription.vue'
import TheOnlineServices from '@/components/organisms/TheOnlineServices.vue'
import TheSearchImageOnTopPage from '@/components/organisms/TheSearchImageOnTopPage.vue'
import TheSiteGuidance from '@/components/organisms/TheSiteGuidance.vue'

export default defineComponent({
  components: {
    TheSpotlight,
    CollectionList,
    NewsList,
    TheSiteDescription,
    TheOnlineServices,
    TheSearchImageOnTopPage,
    TheSiteGuidance,
  },
  props: {
    collections: {
      type: Array,
      default: () => [],
    },
    spotCollections: {
      type: Array,
      default: () => [],
    },
    imageQueries: {
      type: Array,
      default: () => [],
    },
  },
})
