import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f1cc4d1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-image-file" }
const _hoisted_2 = {
  key: 0,
  class: "input-image-file-preview has-background-contents shadow"
}
const _hoisted_3 = {
  class: "input-image-file-name",
  "aria-live": "polite"
}
const _hoisted_4 = { class: "input-image-file-drop-area-container" }
const _hoisted_5 = { class: "input-image-file-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppImgFileSource = _resolveComponent("AppImgFileSource")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!
  const _component_InputFile = _resolveComponent("InputFile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.state.fileList && _ctx.isThumbnailDisplay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.fileList, (file) => {
            return (_openBlock(), _createElementBlock("figure", {
              key: file.name
            }, [
              _createVNode(_component_AppImgFileSource, {
                file: file,
                alt: "preview"
              }, null, 8, ["file"])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.fileName), 1),
          _createVNode(_component_ButtonIcon, {
            onClick: _withModifiers(_ctx.remove, ["prevent"]),
            onKeydown: _withKeys(_withModifiers(_ctx.remove, ["prevent"]), ["space","enter"]),
            icon: "minus-circle",
            styled: "is-none",
            "aria-label": _ctx.$t('label.clearSelect')
          }, null, 8, ["onClick", "onKeydown", "aria-label"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["input-image-file-main has-background-contents", { 'shadow': _ctx.isShadow }])
    }, [
      _renderSlot(_ctx.$slots, "description", {}, undefined, true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          onDragover: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.state.dragOver = true), ["prevent"])),
          onDragleave: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.state.dragOver = false), ["prevent"])),
          onDrop: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.drop && _ctx.drop(...args)), ["prevent"])),
          class: _normalizeClass([{ 'active': _ctx.state.dragOver }, "input-image-file-drop-area"])
        }, [
          _renderSlot(_ctx.$slots, "placeholder", {}, undefined, true)
        ], 34)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_InputFile, {
          onInputFile: _ctx.change,
          "input-id": "input-image-file",
          label: _ctx.$t('parts.selectFile'),
          accept: _ctx.accept.join(','),
          ref: "inputImageFile"
        }, null, 8, ["onInputFile", "label", "accept"])
      ])
    ], 2)
  ]))
}