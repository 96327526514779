
import { defineComponent, reactive, computed, ref } from 'vue'
import ButtonIcon from './ButtonIcon.vue'
import InputFile from './InputFile.vue'
import AppImgFileSource from '../atoms/AppImgFileSource.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    ButtonIcon,
    InputFile,
    AppImgFileSource,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: Array,
      default: () => ['image/jpeg', 'image/png'],
    },
    isThumbnailDisplay: {
      type: Boolean,
      default: true,
    },
    isShadow: {
      type: Boolean,
      default: true,
    },
  },
  setup (props, context) {
    const i18n = useI18n()
    const state = reactive<{
      dragOver: boolean,
      fileList: FileList | null
    }>({
      dragOver: false,
      fileList: null,
    })

    const fileName = computed(() => {
      if (state.fileList?.length) {
        const file = state.fileList[0]
        return file.name
      } else {
        return i18n.t('label.notSelected')
      }
    })

    const change = (files: FileList) => {
      state.fileList = files
      context.emit('input-image', state.fileList)
    }

    const inputImageFile = ref()

    const drop = (e: DragEvent) => {
      if (e.dataTransfer) {
        if (props.accept.length === 0 || props.accept.includes(e.dataTransfer.files[0].type)) {
          state.fileList = e.dataTransfer.files
          inputImageFile.value.clear()
          context.emit('input-image', state.fileList)
        } else {
        }
      }
      state.dragOver = false
    }

    const remove = () => {
      state.fileList = null
      inputImageFile.value.clear()
      context.emit('input-image', state.fileList)
    }

    return {
      state,
      fileName,
      inputImageFile,
      change,
      drop,
      remove,
    }
  },
})
