
import { defineComponent } from 'vue'
import TextLinkWithArrow from '@/components/molecules/TextLinkWithArrow.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    TextLinkWithArrow,
  },
  setup () {
    const i18n = useI18n()
    const lang = i18n.locale
    return {
      lang,
    }
  },
})
