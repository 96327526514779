import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "the-list-about" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextLinkWithArrow = _resolveComponent("TextLinkWithArrow")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", null, [
      _createVNode(_component_TextLinkWithArrow, { to: { name: 'Intro' } }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('siteDescription.intro')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_TextLinkWithArrow, { to: "/fulltext-search" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('siteDescription.fulltext')), 1)
        ]),
        _: 1
      })
    ]),
    (_ctx.lang==='ja')
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createVNode(_component_TextLinkWithArrow, { href: "https://www.ndl.go.jp/jp/use/digital_transmission/index.html" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('siteDescription.use')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("li", null, [
      _createVNode(_component_TextLinkWithArrow, { to: "/soshin_librarylist" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('siteDescription.librarylist')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_TextLinkWithArrow, { to: "/contact#index-2" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('siteDescription.contact')), 1)
        ]),
        _: 1
      })
    ]),
    (_ctx.lang==='ja')
      ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
          _createVNode(_component_TextLinkWithArrow, { to: { name: 'PublicSearchPage' } }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('siteDescription.publicsearch')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("li", null, [
      _createVNode(_component_TextLinkWithArrow, {
        href: _ctx.$t('link.transmissionServiceForLibrarians')
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('siteDescription.transmission')), 1)
        ]),
        _: 1
      }, 8, ["href"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_TextLinkWithArrow, { to: "/FAQ" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('siteDescription.faq')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}