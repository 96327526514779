import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a536a88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "the-image-search-on-top-page" }
const _hoisted_2 = { class: "the-image-search-on-top-page-header-mobile is-hidden-desktop has-background-contents shadow" }
const _hoisted_3 = { class: "level is-mobile" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "title is-hidden-mobile" }
const _hoisted_6 = { class: "the-image-search-on-top-page-body level is-hidden-mobile" }
const _hoisted_7 = { class: "the-image-search-on-top-page-url has-background-contents shadow" }
const _hoisted_8 = { role: "search" }
const _hoisted_9 = { class: "has-text-modest" }
const _hoisted_10 = { class: "level-right is-mobile" }
const _hoisted_11 = { class: "the-image-search-on-top-page-example has-background-contents shadow" }
const _hoisted_12 = { class: "the-image-search-on-top-page-sample-images level is-mobile" }
const _hoisted_13 = ["onClick", "onKeydown", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DssIcon = _resolveComponent("DssIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_InputImageFile = _resolveComponent("InputImageFile")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppImg = _resolveComponent("AppImg")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: { name: 'SearchImage' },
        class: "level is-mobile has-text-link"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_DssIcon, { icon: "image" }),
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('label.imageSearch')), 1)
          ]),
          _createVNode(_component_DssIcon, { icon: "arrow-right" })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('label.imageSearch')), 1),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_InputImageFile, {
        onInputImage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchImage($event))),
        class: "the-image-search-on-top-page-main is-hidden-mobile",
        role: "search"
      }, {
        description: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.imageSearchByFile')), 1)
        ]),
        placeholder: _withCtx(() => [
          _createVNode(_component_DssIcon, { icon: "image" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('label.imageSearchPlaceholder')), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.imageSearchByWebImage')), 1),
        _createElementVNode("form", _hoisted_8, [
          _createVNode(_component_AppInput, {
            modelValue: _ctx.state.imageUrl,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.imageUrl) = $event)),
            placeholder: "https://website/image.jpg",
            label: _ctx.$t('label.imageUrl')
          }, null, 8, ["modelValue", "label"]),
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('label.webImageSearchDescription')), 1),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_AppButton, {
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.searchImageByUrl(_ctx.state.imageUrl)), ["prevent"])),
              onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.searchImageByUrl(_ctx.state.imageUrl)), ["prevent"]), ["space","enter"])),
              styled: "is-accent",
              type: "submit",
              disabled: !_ctx.isVaildWebImageSearch
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('parts.search')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.imageSearchByDigitalCollection')), 1),
        _createElementVNode("div", _hoisted_12, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageQueries, (query) => {
            return (_openBlock(), _createElementBlock("a", {
              key: query.id,
              onClick: _withModifiers(($event: any) => (_ctx.searchSampleImage(query.id)), ["prevent"]),
              onKeydown: _withKeys(_withModifiers(($event: any) => (_ctx.searchSampleImage(query.id)), ["prevent"]), ["space","enter"]),
              tabindex: "0",
              class: _normalizeClass(`the-image-search-on-top-page-sample-image image-search-sample-${query.id}`),
              role: "link",
              "aria-label": _ctx.$t('label.imageSearchByDigitalCollectionInSample', {id: query.id})
            }, [
              _createElementVNode("figure", null, [
                _createVNode(_component_AppImg, {
                  src: query.thumbnail,
                  alt: ""
                }, null, 8, ["src"])
              ]),
              _createVNode(_component_ButtonIcon, {
                styled: "is-none",
                icon: "magnifyingglass",
                text: _ctx.$t('parts.search'),
                class: "is-hidden-mobile"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('parts.search')), 1)
                ]),
                _: 1
              }, 8, ["text"])
            ], 42, _hoisted_13))
          }), 128))
        ])
      ])
    ])
  ]))
}